/* ------ RESET ------ */

* {
	margin: 0;
	padding: 0;
	border: none;
	background: none;

	font: inherit;
	font-size: 100%;
	line-height: inherit;
	text-decoration: inherit;
	color: inherit;

	text-align: inherit;
	vertical-align: baseline;

	box-sizing: inherit;
}

textarea {
	resize: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

*:focus {
	outline: none;
}

*::placeholder {
	opacity: 1;
}

textarea {
	resize: none;
}

/** Restore defaults */

b,
strong {
	font-weight: 600;
}

ul, ol {
	margin-left: 16px;
}

i,
em {
	font-style: italic;
}

/** Removes input type="number" arrows (standard) */
input[type='number'] {
	appearance: textfield;
	-moz-appearance: textfield;
}

/* (legacy chrome, safari) */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	appearance: none;
	-webkit-appearance: none;
	margin: 0;
}

/* ------ DEFAULTS ------ */

html {
	box-sizing: border-box;
	font-family: Poppins, Verdana, Geneva, Tahoma, sans-serif;
	background-color: #FCFBFF;
}

a {
	color: rgb(255, 89, 72);
	text-decoration: underline;
}

a:hover, a:active {
	color: #CA2323;
}

a:focus {
	outline: 1px dotted rgb(255, 89, 72);
}
