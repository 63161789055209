.PaymentReceipt {
	padding: 2rem 0;
}

.PaymentReceipt > .row {
	display: flex;
	justify-content: space-between;
}

.PaymentReceipt > .row:not(:last-of-type) {
	border-block-end: 1px solid;
}

.PaymentReceipt > .row > span {
	font-size: 1.2rem;
    line-height: 2;
}

.PaymentReceipt > .button-container {
	margin-block-start: 2rem;
}

.PaymentReceipt > .button-container > button {
	text-transform: none;
	background: linear-gradient(to right, #feb940, #f4085f);
	border-radius: 20px;
	color: white;
	width: 10rem;
}

@media print {

	.PaymentReceipt > .button-container > button {
		display: none !important;
	}

}